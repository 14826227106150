import { Component, ChangeDetectionStrategy, Inject, NgZone } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AppIconService } from './core/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent {
  constructor(private appIconService: AppIconService) {
    this.appIconService.init();
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TreelistsState } from '../reducers/treelist.reducers';
import { Treelist } from '../models/treelist.model';
import { State } from '../models/state.model';

export const getTreelistsState = createFeatureSelector<State>('treelists');

export const getTreelists = createSelector(getTreelistsState, (state: State) => state.treelists);

export const getTreelistById = (id: string) => createSelector(getTreelists, (state: TreelistsState) => state.entities[id]);

export const getTreelistStateById = (id: string) => createSelector(getTreelistById(id), (state: Treelist) => state.state);

export const getTreelistPayloadById = (id: string) => createSelector(getTreelistById(id), (state: Treelist) => state?.payload);

export const getTreelistSelectionsById = (id: string) =>
  createSelector(getTreelistById(id), (state: Treelist) => state?.payload?.selections);

export const getTreelistSelectionsDataById = (id: string) =>
  createSelector(getTreelistById(id), (state: Treelist) => state?.payload?.selectionsData);

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { CONSTANTS } from '@cue/admin-constants';
import { AccountService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class LicenseExistGuard {
  constructor(
    public router: Router,
    private accountService: AccountService,
  ) {}

  licenseExist(): Observable<boolean> {
    return this.accountService.licenseExist();
  }

  onLogout() {
    this.accountService.actionLogout().subscribe((_) => {
      this.router.navigateByUrl('/account/login');
    });
  }

  canActivate(): boolean | Observable<boolean> | Promise<boolean> {
    return this.licenseExist().pipe(
      tap((x) => {
        if (!x) {
          this.onLogout();
          this.router.navigate([CONSTANTS.paths.account, CONSTANTS.paths.uploadCertificate]);
        }
      }),
    );
  }
}

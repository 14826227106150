import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { ConfigService } from '@cue/admin-core';
import { appConfig } from './app.config';
import { AppComponent } from './app/app.component';
import { env } from './environments/environment';
if (env.production) {
  enableProdMode();
}

const configService = new ConfigService();
const bootstrap = () => bootstrapApplication(AppComponent, appConfig(configService));

configService.load().then(
  () => {
    return bootstrap();
  },
  (reject) => {
    console.error('Cannot load start the application');
    window.alert(reject);
  },
);

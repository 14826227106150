import { ActionReducerMap, combineReducers, provideState } from '@ngrx/store';
import { reducer } from './reducers/treelist.reducers';
import { provideEffects } from '@ngrx/effects';
import { TreelistsEffects } from './effects/treelist.effects';
import { State } from './models';

export const reducers: ActionReducerMap<State> = {
  treelists: reducer,
};

export const treelistProviders = [
  provideState({
    name: 'treelists',
    reducer: combineReducers(reducers),
  }),
  provideEffects([TreelistsEffects]),
];

import { createAction, props } from '@ngrx/store';
import { DataSourceRequestState } from '@progress/kendo-data-query';
import { TreelistErrors } from '../models/treelist-errors.model';

export const RefreshTreelistDataAction = createAction(
  '[Treelist Service] Treelist Refresh data',
  props<{
    id: string;
    urlPostfix: string;
    settings?: DataSourceRequestState;
    addition?;
    payload?;
  }>(),
);

export const TreelistChangeSelections = createAction('[Treelist Service] Treelist change selections', props<{ id: string }>());

export const TreelistClearSelections = createAction('[Treelist Service] Treelist clear selections', props<{ id: string }>());

export const TreelistDataRefreshSuccessAction = createAction(
  '[Treelist Service] Treelist Data Loaded Success',
  props<{
    id: string;
    treeListResponse: any /*, total: number*/;
    treeListErrors: TreelistErrors;
  }>(),
);

export const TreelistDataRefreshFailedAction = createAction('[Treelist Service] Treelist Data Loaded Fail', props<{ id: string }>());

export const TreelistSetSelections = createAction(
  '[Treelist Service] Treelist set new selections',
  props<{ id: string; newSelections: any[] }>(),
);

export const TreelistSetSelectionsData = createAction(
  '[Treelist Service] Treelist set new selections data',
  props<{ id: string; newSelectionsData: any[] }>(),
);
